import React, {Fragment} from "react"
import PropTypes from "prop-types"
import {Helmet} from 'react-helmet'

import favicon from '../assests/favicon.ico'

import "../styles/layout.css"

const Layout = ({ children }) => {
  return (
    <Fragment>
      <Helmet>
        <link rel="icon" href={favicon} />
        <link
          href="https://fonts.googleapis.com/css?family=Nunito&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()} Souvik Banerjee.
      </footer>
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
